import React from 'react';
import Marquee from "react-marquee-slider";
import Partner1 from '../assets/images/partners/partner-1.png';
import Partner2 from '../assets/images/partners/partner-2.png';
import Partner3 from '../assets/images/partners/partner-3.png';
import Partner4 from '../assets/images/partners/partner-4.png';
import Partner5 from '../assets/images/partners/partner-5.png';
import Partner6 from '../assets/images/partners/partner-6.png';
import Partner7 from '../assets/images/partners/partner-7.png';
import Partner8 from '../assets/images/partners/partner-8.png';
import Partner9 from '../assets/images/partners/partner-9.png';
import Partner10 from '../assets/images/partners/partner-10.png';
import SectionHeading from "./SectionHeading";

function Partners(props) {
  return (
    <div className={'partners-section'} id={'partners'}>
      <div className="title">
        <SectionHeading
          title={'Our Partners'}
          subtitle={'With more added monthly.'}
        />
      </div>
      <div className="logos">
        <Marquee>
          <img src={Partner1} alt={'Partner'}/>
          <img src={Partner2} alt={'Partner'}/>
          <img src={Partner3} alt={'Partner'}/>
          <img src={Partner4} alt={'Partner'}/>
          <img src={Partner5} alt={'Partner'}/>
          <img src={Partner6} alt={'Partner'}/>
          <img src={Partner7} alt={'Partner'}/>
          <img src={Partner8} alt={'Partner'}/>
          <img src={Partner9} alt={'Partner'}/>
          <img src={Partner10} alt={'Partner'}/>
        </Marquee>
      </div>
    </div>
  );
}

export default Partners;
