import React from 'react';
import Feature from "./Feature";
import media from "../assets/images/features/media";
import arbitrage from "../assets/images/features/arbitrage";
import learning from "../assets/images/features/learning";
import investments from "../assets/images/features/investments";
import collectibles from "../assets/images/features/collectibles";
import emergingMarkets from "../assets/images/features/emerging-markets";
import SectionHeading from "./SectionHeading";

function Features(props) {
  return (
    <div className={'features-section'} id={'features'}>
      <div className="container">
        {/*<SectionHeading*/}
        {/*  title={<>M<span>.</span>A<span>.</span>L<span>.</span>I<span>.</span>C<span>.</span>E</>}*/}
        {/*  subtitle={'Features subtitle (title may not be needed)'}*/}
        {/*/>*/}
        <div className="features-grid">
          <Feature
            title={'Media'}
            description={'Encompassing art, music mediums, event tickets, modern games and more, Malice showcases the fastest growing media markets.'}
            icon={media}
          />
          <Feature
            title={'Arbitrage'}
            description={'Dominate the markets with exclusive auctions, selling/botting tools, grading guidance, Amazon Ungating, FBA Leads, and business creation tools.'}
            icon={arbitrage}
          />
          <Feature
            title={'Learning'}
            description={'Fuel your growth with knowledge sourced from the Malice community—Individual coaching, video tutorials, workshops, one-on-one support, and expert guides. '}
            icon={learning}
          />
          <Feature
            title={'Investments'}
            description={'Strengthen your portfolio with short term and long term high yield acquisitions such as coins, sealed games, virtual investments, computer components, retail drops and more. '}
            icon={investments}
          />
          <Feature
            title={'Collectibles'}
            description={'Whether you are growing your personal collections or seeking to increase your profits, Malice provides guidance in movie memorabilia, comic books, vintage toys, vintage games and more that only Malice members know. '}
            icon={collectibles}
          />
          <Feature
            title={'Emerging Markets'}
            description={'Explore new opportunities for financial growth in trending markets such as blockchain media, options, mining, strategic passive income streams, and cutting edge digital assets. '}
            icon={emergingMarkets}
          />
        </div>
      </div>
    </div>
  );
}

export default Features;
