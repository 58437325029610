import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Logo from '../assets/images/logo.svg';
import MenuIcon from '../assets/images/menu.svg';
import DashboardIcon from '../assets/images/dashboard.svg';

function Navigation(props) {
  const [isShown, setIsShown] = useState(false);
  return (
    <nav className={isShown ? 'shown' : ''}>
      <div className="container">
        <button
          onClick={() => setIsShown(prevState => !prevState)}
          className="button alt mobile"><img src={MenuIcon} /></button>
        <img src={Logo} alt="" className="logo"/>
        <ul>
          <li onClick={() => setIsShown(false)}><AnchorLink href="#features">Features</AnchorLink></li>
          <li onClick={() => setIsShown(false)}><AnchorLink href="#partners">Partners</AnchorLink></li>
          <li onClick={() => setIsShown(false)}><AnchorLink href="#faq">FAQ</AnchorLink></li>
          <li onClick={() => setIsShown(false)}><AnchorLink href="#contact">Contact</AnchorLink></li>
        </ul>
        <a href="https://malice.metalabs.gg/login" className="button desktop">Dashboard</a>
        <a className="button alt mobile" href={'https://malice.metalabs.gg/login'}><img src={DashboardIcon} /></a>
      </div>
    </nav>
  );
}

export default Navigation;
