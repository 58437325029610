import React from 'react';
import SectionHeading from "./SectionHeading";
import Question from "./Question";

function Faq(props) {
  return (
    <div className={'faq-section'} id={'faq'}>
      <div className="container">
        <SectionHeading
          title={'FAQ'}
          subtitle={'Everything you need to know.'}
        />
        <div className="faqs">
          <div className="col">
            <Question
              question={'How much is Malice?'}
              answer={'Malice is $60/month. This grants you access to the most exclusive drops, inside information on trending markets, and expert guidance for your personal and professional development. You’ll also be a part of Malice exclusive group buys with our partners in the Malice Network. But most importantly, you’ll earn a spot in the family. '}
            />
            <Question
              question={'What is the Malice Network?'}
              answer={'Malice partners with the most exclusive groups, tools, and bots in the space. Unparalleled opportunities with OminiousAIO, Stellar, Koi, PokeMRKT, TheBotBible, PPBot, PopBotAIO, ZenyScripts, Ethereal Scripts, AYCD, and more added every month.'}
            />
            <Question
              question={'What differentiates Malice from other groups?'}
              answer={'Malice isn’t just a group—it’s a lifestyle. Our family members are focused on constant, measurable growth in both professional and personal development. Through continuous learning, feedback, and educated risk-taking, our members are creating their best versions of themselves. You’ll come in for our profit strategies but you’ll stay for your personal growth. We provide you with the information, but YOU engineer how that information will shape your future. '}
            />
            <Question
              question={'What does a day in Malice look like?'}
              answer={'No two days are the same, as drop schedules and releases are ever changing. But what doesn’t change is the Malice commitment to education in areas of passive income, and the focus on improving as an individual in health and wellness, fitness, mental health and more. Malice has a place for everyone, resellers, collectors, and beyond. '}
            />
          </div>
          <div className="col">
            <Question
              question={'The L in Malice stands for learning—how does that work?'}
              answer={'Malice offers expert guidance on the six primary categories—Media, arbitrage, learning, investments, collectibles, and emerging markets. So how do you make use of that? Malice Maker offers courses, webinars, and workshops with clear, focused goals. Experts also offer one on one advice in the channels and through tickets. But learning also happens through you—the members.  Our members bring their experiences and expertise to the family, and share that information in ways that create real growth opportunities. '}
            />
            <Question
              question={'Besides Arbitrage and reselling opportunities, what does Malice offer?'}
              answer={'Malice offers the opportunity to shape the life you want. Who are you currently? Who do you want to be? What steps will you take to get there? Malice touches all aspects of your life—from health and fitness, to financial planning, credit repair, tax preparation, business creation and beyond. Let Malice show you the way to your future.'}
            />
            <Question
              question={'What is the Cult of Malice?'}
              answer={'While all Malice members are family, we reserve a special role for those who embody the mask, commit to the brand and represent what it means to grow. When becoming a part of the cult, you’ll receive additional and exclusive giveaways from the team, giveaways from other cult members, unlock private channels, acquire beta test opportunities for new and upcoming features, and additional features that are added weekly. '}
            />
            <Question
              question={'When will you restock?'}
              answer={'As public restocks are permanently closed, the only way into Malice is through reputation within our current member circle, and by engaging on our Social Media outreach. Opportunities are earned or won by current members to invite Friends & Family each month.  Follow our Twitter & Instagram for more information!'}
            />
          </div>
        </div>
      </div>
      <div className="particles" />
    </div>
  );
}

export default Faq;
