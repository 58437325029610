const plus = (
  <svg width="21px" height="20px" viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.800000012">
      <g id="Landing" transform="translate(-777.000000, -2793.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="Group-12" transform="translate(-664.000000, 2506.000000)">
          <g id="Group-19" transform="translate(764.000000, 95.000000)">
            <g id="Group-11-Copy" transform="translate(369.000000, 168.000000)">
              <path d="M329,35.4285714 C328.468065,35.1442969 327.523548,35.0021596 326.16645,35.0021596 C324.809352,35.0021596 322.896725,35.1442969 320.428571,35.4285714 C320.122014,36.4982265 319.968735,37.6136634 319.968735,38.7748819 C319.968735,39.9361005 320.122014,41.6778065 320.428571,44 L317.571429,43.5410581 C317.817445,43.2612284 317.940453,42.4803165 317.940453,41.1983225 C317.940453,39.9163284 317.817445,37.9930781 317.571429,35.4285714 C315.050062,35.1442969 313.098506,35.0021596 311.716762,35.0021596 C310.335018,35.0021596 309.429431,35.1442969 309,35.4285714 C308.116602,34.230804 308.116602,33.4231214 309,33.0055236 C309.883398,32.5879259 312.740541,32.4432275 317.571429,32.5714286 C317.817445,29.7815748 317.940453,27.685812 317.940453,26.2841403 C317.940453,24.8824685 317.817445,24.1210885 317.571429,24 L320.428571,24 C320.122014,26.3956725 319.968735,28.1850932 319.968735,29.3682623 C319.968735,30.5514314 320.122014,31.6191535 320.428571,32.5714286 C322.320083,32.3773875 323.907704,32.2803669 325.191435,32.2803669 C326.475167,32.2803669 327.57573,32.5220858 328.493125,33.0055236 L329,35.4285714 Z" id="Path" transform="translate(318.668726, 34.000000) scale(-1, 1) translate(-318.668726, -34.000000) "/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default plus;
