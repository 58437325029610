import React from 'react';

function Header(props) {
  return (
    <header className={'header'}>
      <div className="container">
        <div className="cta">
          <div className="button-group">
            <a href="" className="button">Sold Out</a>
          </div>
        </div>
      </div>
      <div className="main-graphic" />
      <div className="purple-text" />
      <div className="text" />
      <div className="particles" />
      <div className="particles secondary" />
    </header>
  );
}

export default Header;
