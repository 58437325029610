import React, {useState} from 'react';
import AnimateHeight from "react-animate-height";
import plus from "../assets/images/plus";

function Question({ question, answer }) {
  const [isOpen, setIsOpen] = useState();

  return (
    <div className={`question${isOpen ? ' open' : ''}`} onClick={() => setIsOpen(prevState => !prevState)}>
      <header>
        <h3>{ question }</h3>
        { plus }
      </header>
      <AnimateHeight height={isOpen ? 'auto': 0}>
        <main>
          <p>{ answer }</p>
        </main>
      </AnimateHeight>
    </div>
  );
}

export default Question;
