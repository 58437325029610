const emergingMarkets = (
  <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Landing" transform="translate(-770.000000, -1736.000000)" fill="#FFFFFF" fillRule="nonzero">
        <g id="Group-6" transform="translate(0.000000, 1023.000000)">
          <g id="Group-5-Copy-4" transform="translate(770.000000, 704.000000)">
            <g id="growth" transform="translate(0.000000, 9.000000)">
              <path d="M38.8232813,37.65625 C39.6077604,36.6917411 40,33.1920294 40,27.157115 C40,21.1222005 39.6077604,12.0698289 38.8232813,0 C34.9243045,0.493615391 31.9567144,0.740423087 29.920511,0.740423087 C27.8843075,0.740423087 26.1480956,0.493615391 24.711875,0 L24.711875,9.4140625 C22.3410146,9.84149068 20.4437872,10.0552048 19.020193,10.0552048 C17.5965988,10.0552048 15.5713001,9.84149068 12.9442969,9.4140625 L12.9442969,18.828125 C9.7320482,19.3726308 7.41111433,19.6448836 5.98149526,19.6448836 C4.55187618,19.6448836 2.95028402,19.3726308 1.17671875,18.828125 C1.74887216,23.6515074 2.03494887,27.1645413 2.03494887,29.3672266 C2.03494887,31.5699119 1.74887216,34.3329197 1.17671875,37.65625 L0,37.65625 L0,40 C10.7058983,39.3969969 18.94319,39.0954954 24.711875,39.0954954 C30.48056,39.0954954 35.5766017,39.3969969 40,40 L40,37.65625 L38.8232813,37.65625 Z M3.52046875,37.65625 L3.52046875,21.171875 L12.9442969,21.171875 L12.9442969,37.65625 L3.52046875,37.65625 Z M15.2880469,37.65625 L15.2880469,11.7578125 L24.711875,11.7578125 L24.711875,37.65625 L15.2880469,37.65625 Z M27.055625,37.65625 L27.055625,2.34375 L36.4794531,2.34375 L36.4794531,37.65625 L27.055625,37.65625 Z" id="Shape"/>
              <path d="M28.41,8.51195313 L29.8876563,10.33125 L30.5953906,9.75648438 C30.8715127,14.4452505 31.0095737,18.2503807 31.0095737,21.171875 C31.0095737,24.0933693 30.8715127,28.4097755 30.5953906,34.1210938 L32.4598131,34.1210938 C32.4598131,34.0086622 32.4598131,31.9838584 32.4598131,28.0466821 C32.4598131,24.1095059 32.619589,18.0127733 32.9391406,9.75648438 L33.6471875,10.3315625 L35.1248438,8.51226563 C34.3851295,7.93899346 33.7846347,7.46503303 33.3233594,7.09038434 C32.862084,6.71573565 32.4620648,6.48562332 32.1233016,6.40004735 C31.8040439,6.77279321 31.4328012,7.13103554 31.0095737,7.47477434 C30.5863462,7.81851313 29.7198216,8.16423939 28.41,8.51195313 Z" id="Path"/>
              <path d="M16.6425781,17.9260937 L18.1202344,19.7453906 L18.8279688,19.170625 C19.1203035,23.7048542 19.2664708,27.1193333 19.2664708,29.4140625 C19.2664708,31.7087917 19.1203035,33.2778021 18.8279688,34.1210938 L21.1717188,34.1210938 C20.9605722,29.9206322 20.8549989,26.7513874 20.8549989,24.6133594 C20.8549989,22.4753314 20.9605722,20.6610605 21.1717188,19.1705469 L21.8797656,19.745625 L23.3574219,17.9263281 L19.9998438,15.199375 L16.6425781,17.9260937 Z" id="Path"/>
              <path d="M4.875,27.3400781 C5.29656794,27.4709643 5.60006295,27.6505537 5.78548502,27.8788461 C5.9709071,28.1071386 6.15996418,28.5339816 6.35265625,29.159375 L7.06039062,28.5846094 C7.3939218,28.0878977 7.56068739,28.3643821 7.56068739,29.4140625 C7.56068739,30.4637429 7.3939218,32.0327533 7.06039062,34.1210937 L9.04144523,33.8740745 L9.40414062,28.5846094 L10.1121875,29.1596875 C9.88212202,28.5890509 9.88212202,28.1621038 10.1121875,27.8788461 C10.342253,27.5955885 10.8348051,27.4161033 11.5898437,27.3403906 C10.8223188,26.3723633 10.2411104,25.7279837 9.84621863,25.4072519 C9.45132685,25.08652 8.91334252,24.8218892 8.23226562,24.6133594 C7.43071488,25.338002 6.80417843,25.8836829 6.35265625,26.2504022 C5.90113407,26.6171214 5.40858199,26.9803468 4.875,27.3400781 Z" id="Path"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default emergingMarkets;
