import React from 'react';

function Feature({ title, description, icon }) {
  return (
    <div className={'feature'}>
      <div className="icon">
        { icon }
      </div>
      <div className="text">
        <h3><span>{ title.substring(0, 1) }</span>{ title.substring(1) }</h3>
        <p>{ description }</p>
      </div>
    </div>
  );
}

export default Feature;
