import React from 'react';
import TwitterIcon from '../assets/images/twitter.svg';
import InstagramIcon from '../assets/images/instagram.svg';
import TwitterIconM from '../assets/images/twitter-m.svg';
import InstagramIconM from '../assets/images/instagram-m.svg';
import Logo from '../assets/images/logo.svg';

function Footer(props) {
  return (
    <footer>
      {/*<div className="top">*/}
      {/*  <div className="container">*/}
      {/*    <img src={Logo} alt="" className="logo" />*/}
      {/*    <div className="footer-nav">*/}
      {/*      <h3>Category Title</h3>*/}
      {/*      <ul>*/}
      {/*        <li>*/}
      {/*          <a href="">Example Link</a>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <a href="">Example Link</a>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <a href="">Example Link</a>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*    <div className="footer-nav">*/}
      {/*      <h3>Category Title</h3>*/}
      {/*      <ul>*/}
      {/*        <li>*/}
      {/*          <a href="">Example Link</a>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <a href="">Example Link</a>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <a href="">Example Link</a>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*    <div className="footer-nav">*/}
      {/*      <h3>Category Title</h3>*/}
      {/*      <ul>*/}
      {/*        <li>*/}
      {/*          <a href="">Example Link</a>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <a href="">Example Link</a>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <a href="">Example Link</a>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*    <div className="socials mobile">*/}
      {/*      <a href="">*/}
      {/*        <img src={TwitterIconM} alt={'Twitter'} />*/}
      {/*      </a>*/}
      {/*      <a href="">*/}
      {/*        <img src={InstagramIconM} alt={'Twitter'} />*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="bottom" id={'contact'}>
        <div className="container">
          <p>Copyright &copy; 2021 Malice</p>
          <a href="mailto:Malice@WeAreMalice.com">Malice@WeAreMalice.com</a>
          <div className="socials desktop">
            <a href="https://twitter.com/wearemalice">
              <img src={TwitterIcon} alt={'Twitter'} />
            </a>
            <a href="https://www.instagram.com/we.are.malice">
              <img src={InstagramIcon} alt={'Twitter'} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
