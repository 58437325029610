import React from 'react';

function SectionHeading({ title, subtitle }) {
  return (
    <div className={'section-heading'}>
      <h2>{ title }</h2>
      <p>{ subtitle }</p>
    </div>
  );
}

export default SectionHeading;
